import React from 'react'
import { IServiceDetailsProps } from '../contracts/IServiceDetailsProps'

const ServiceDetails = (props: IServiceDetailsProps) => {
  return (
    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
      <div className="relative">
        <dl className="mt-10 space-y-10">
          {props.leftFeatures.map((item) => (
            <div key={item.id} className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sekyee-blue-light text-white">
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
            </div>
          ))}
        </dl>
      </div>

      <div className="relative">
        <dl className="mt-10 space-y-10">
          {props.rightFeatures.map((item) => (
            <div key={item.id} className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sekyee-blue-light text-white">
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
            </div>
          ))}
        </dl>
        <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
          <svg
            className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
            width={784}
            height={404}
            fill="none"
            viewBox="0 0 784 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default ServiceDetails