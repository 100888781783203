import React from 'react'
import { PageProps } from 'gatsby'

import { GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'
import ServiceDetails from '../components/ServiceDetails'
import Footer from '../components/Footer'
import Header from '../components/Header'

const leftFeatureItems = [
  {
    id: 1,
    name: 'Managed Recovery Programme',
    description:
      `We ensure that your data is protected, retrievable and provide solutions to ensure that there is a plan in place in the event of cyber-attacks or physical factors which could impact your data and business continuity. 
      1) Cloud back-up solutions
      2) On premise back-up solutions
      3) Disaster recovery planning
      4) Loan servers (hot swap)
      5) 24/7 network and server monitoring`,
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'Office backup',
    description:
      `Automated daily backup of crucial admin / SIMS data and daily notification email to confirm a successful backup. Data is retained for a maximum of 6 months including 60 days’ worth of daily backup
      
      Full offsite backup
      We can offer a full offsite backup and recovery solution that will back up your entire server and restore it to its previous state in the event of a disaster. Please contact us for more information.
      `,
    icon: LightningBoltIcon,
  },
]
const rightFeatureItems = [
  {
    id: 1,
    name: 'Backup and data protection',
    description:
      `Sekyee is registered with the Information Commissioner’s Office and fully complies with the requirements of the Data Protection Act. The data is fully encrypted from your systems and  is held in our secure office data centre and stored exclusively in the United Kingdom. 

      - Data storage solutions
      - Automated daily backup
      - Fast recovery
      - Stored in our highly secure vault infrastructure
      - Reliable restore process
      `,
    icon: ScaleIcon,
  },
  {
    id: 2,
    name: 'Cloud backup',
    description:
      `We can help you better manage and protect your business through proactive service and state-of-the-art solutions for compliance, analytics and surveillance. 
      Please contact us for more information.`,
    icon: MailIcon,
  },
]

const DisasterRecovery = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <div className="py-12 bg-white overflow-hidden lg:py-12">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                DISASTER RECOVERY SOLUTIONS
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                Our Disaster Recovery Services ensure IT failures and ransomware attacks do not affect your organisation’s time, resources, and costs.
              </p>
            </div>

            <ServiceDetails leftFeatures={leftFeatureItems} rightFeatures={rightFeatureItems} />

            
          </div>
        </div>
      </main>
    
      <Footer />
    </div>
  )
}

export default DisasterRecovery